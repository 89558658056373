import {SIDEBAR_MENU_ITEMS} from "./sidebar-menu-items";
import {SidebarMenuItem} from "../../model/sidebar-menu-item";
import {MeupCommonOptions} from "../../model/meup-common-options";
import {SidebarOptions} from "../../model/sidebar-options";

import chevron_left from '../../assets/chevron_left.svg';
import chevron_right from '../../assets/chevron_right.svg';
import close from '../../assets/close.svg';

class Sidebar {

    private sidebarHTMLElement?: HTMLElement;
    private sidebarSecondaryHTMLElement?: HTMLElement;
    private backdropElement?: HTMLElement;
    private sidebarVisible = false;
    private secondarySidebarVisible = false;
    private meupCommonOptions: MeupCommonOptions;
    private sidebarOptions: SidebarOptions;


    constructor() {
        console.log("Sidebar constructor loaded");
    }

    setOptions(meupCommonOptions: MeupCommonOptions, sidebarOptions: SidebarOptions) {
        this.meupCommonOptions = meupCommonOptions;
        this.sidebarOptions = sidebarOptions;
    }

    render(): void {
        //création sidebar principale
        this.sidebarHTMLElement = document.createElement('div');
        this.sidebarHTMLElement.setAttribute('id', 'milleetunparcours-sidebar');
        this.sidebarHTMLElement.classList.add('milleetunparcours-sidebar-container');

        this.sidebarHTMLElement.innerHTML = `           
            <div class="milleetunparcours-sidebar-menu-items">
                ${SIDEBAR_MENU_ITEMS.map((menuItem, i) => this.renderMenuItem(menuItem, i)).join('')}
            </div>        
        `;

        //création backdrop générique
        this.backdropElement = document.createElement('div');
        this.backdropElement.classList.add('milleetunparcours-sidebar-backdrop');
        document.body.insertBefore(this.backdropElement, this.sidebarHTMLElement.nextSibling);
        document.body.prepend(this.sidebarHTMLElement);

        //click backdrop fermeture menu
        this.backdropElement.addEventListener('click', () => {
            if (this.sidebarVisible) {
                this.toggle(false);
            }
        });


        //bind click sur menus parents
        const parentMenuItems = this.sidebarHTMLElement.getElementsByClassName('milleetunparcours-sidebar-link--parent-menu');
        for (let i = 0; i < parentMenuItems.length; i++) {
            const parentMenuItem = parentMenuItems.item(i);
            parentMenuItem.addEventListener('click', () => {
                this.displaySubmenu(parseInt(parentMenuItem.getAttribute('data-menu-idx'), 10));
            });
        }


        //création sidebar secondaire
        this.sidebarSecondaryHTMLElement = document.createElement('div');
        this.sidebarSecondaryHTMLElement.setAttribute('id', 'milleetunparcours-sidebar-secondary');
        this.sidebarSecondaryHTMLElement.classList.add('milleetunparcours-sidebar-secondary-container');

        this.sidebarSecondaryHTMLElement.innerHTML = `           
            <div class="milleetunparcours-sidebar-secondary-menu-items">                
                ${SIDEBAR_MENU_ITEMS.map((menuItem, i) => this.renderSubMenu(menuItem, i)).join('')}                             
            </div>
        `;
        document.body.prepend(this.sidebarSecondaryHTMLElement);

        //bind btns close et back
        this.sidebarSecondaryHTMLElement.querySelectorAll('.milleetunparcours-sidebar-close-btn').forEach((close_btn) => {
            close_btn.addEventListener('click', () => {
                if (this.sidebarVisible) {
                    this.toggle(false);
                }
            });
        });
        this.sidebarSecondaryHTMLElement.querySelectorAll('.milleetunparcours-sidebar-back-btn').forEach((close_btn) => {
            close_btn.addEventListener('click', () => {
                this.secondarySidebarVisible = false;
                this.refreshSecondarySidebarVisiblity();
            });
        });

        this.refreshVisiblity();
    }

    private renderMenuItem(menuItem: SidebarMenuItem, index: number): string {
        if (menuItem.children) {
            return `
                <p class="milleetunparcours-sidebar-link-item milleetunparcours-sidebar-link-item--parent-menu">
                    <a class="milleetunparcours-sidebar-link milleetunparcours-sidebar-link--parent-menu" href="javascript:void(0)" data-menu-idx="${index}">
                        <img src="${menuItem.icon}" alt="${menuItem.titre}"/>
                        <span class="text">${menuItem.titre}</span>
                        <img class="chevron" src="${chevron_right}"/>
                    </a>
                </p>
            `;
        } else {

            const isActive = window.location.href == menuItem.url;
            return `
                <p class="milleetunparcours-sidebar-link-item">
                    <a class="milleetunparcours-sidebar-link ${(isActive ? 'active' : '')}" href="${menuItem.url}" ${(menuItem.appLink ? 'app-key="' + menuItem.appLink + '"' : '')}>
                        <img src="${menuItem.icon}" alt="${menuItem.titre}"/>
                        <span class="text">${menuItem.titre}</span>
                    </a>
                </p>
            `;
        }
    }

    private renderSubMenu(menuItem: SidebarMenuItem, index: number): string {
        if (!menuItem.children) {
            return '';
        }

        return `
            <div class="milleetunparcours-sidebar-secondary-submenu" data-menu-idx="${index}">
                <div class="milleetunparcours-sidebar-secondary-submenu-header">
                    <div class="submenu-header-left">
                        <span><button type="button" class="milleetunparcours-sidebar-btn milleetunparcours-sidebar-back-btn"><img alt="Retour" class="chevron" src="${chevron_left}"/></button></span>
                        <span class="title">${menuItem.titre}</span>
                    </div>                    
                    <span><button type="button" class="milleetunparcours-sidebar-btn milleetunparcours-sidebar-close-btn"><img alt="Fermer" class="chevron" src="${close}"/></button></span>
                </div>
                <div class="milleetunparcours-sidebar-secondary-submenu-items">
                    ${menuItem.children.map((menuItem, i) => this.renderMenuItem(menuItem, i)).join('')}
                </div>
            </div>
        `;
    }

    private displaySubmenu(menuIndex: number) {
        const parent_menus = this.sidebarHTMLElement.getElementsByClassName('milleetunparcours-sidebar-link');
        for (let i = 0; i < parent_menus.length; i++) {
            const parent_menu = parent_menus.item(i);
            parent_menu.classList.toggle('active', parseInt(parent_menu.getAttribute('data-menu-idx'), 10) === menuIndex);
        }

        this.secondarySidebarVisible = true;
        this.refreshSecondarySidebarVisiblity();
        const submenus = this.sidebarSecondaryHTMLElement.getElementsByClassName('milleetunparcours-sidebar-secondary-submenu');
        for (let i = 0; i < submenus.length; i++) {
            const submenu = submenus.item(i);
            submenu.classList.toggle('milleetunparcours-sidebar-secondary-submenu-visible', parseInt(submenu.getAttribute('data-menu-idx'), 10) === menuIndex);
        }
    }


    toggle(toggle?: boolean): void {
        this.sidebarVisible = typeof toggle !== 'undefined' ? toggle : !this.sidebarVisible;
        this.sidebarOptions.onVisibilityChange(this.sidebarVisible);
        this.refreshVisiblity();
    }

    getAppLinks(appKey: string): HTMLElement[] {
        const appLinks = [];
        const links = this.sidebarHTMLElement.getElementsByClassName('milleetunparcours-sidebar-link');
        for (let i = 0; i < links.length; i++) {
            const link = links[i] as HTMLElement;
            //attribut app-key app courante ?
            if (link.hasAttribute('app-key') && link.getAttribute('app-key') === appKey) {
                appLinks.push(link);
            }
        }
        return appLinks;
    }

    private refreshVisiblity(): void {
        this.sidebarHTMLElement.classList.toggle('milleetunparcours-sidebar-visible', this.sidebarVisible);
        this.sidebarSecondaryHTMLElement.classList.toggle('milleetunparcours-sidebar-visible', this.sidebarVisible);
        this.backdropElement.classList.toggle('active', this.sidebarVisible);
        //reset menu selected et sous menus sur cachage sidebar
        if (!this.sidebarVisible) {
            this.secondarySidebarVisible = false;
            const parent_menus = this.sidebarHTMLElement.getElementsByClassName('milleetunparcours-sidebar-link');
            for (let i = 0; i < parent_menus.length; i++) {
                const parent_menu = parent_menus.item(i);
                parent_menu.classList.remove('active');
            }

            const submenus = this.sidebarSecondaryHTMLElement.getElementsByClassName('milleetunparcours-sidebar-secondary-submenu');
            for (let i = 0; i < submenus.length; i++) {
                const submenu = submenus.item(i);
                submenu.classList.remove('milleetunparcours-sidebar-secondary-submenu-visible');
            }
        }
        this.refreshSecondarySidebarVisiblity();
    }


    private refreshSecondarySidebarVisiblity(): void {
        this.sidebarSecondaryHTMLElement.classList.toggle('milleetunparcours-sidebar-secondary-visible', this.secondarySidebarVisible);
    }
}

export default Sidebar;
