import {SidebarMenuItem} from "../../model/sidebar-menu-item";
import books from '../../assets/books.svg';
import workspace from '../../assets/workspace.svg';
import courses from '../../assets/courses.svg';
import factory from '../../assets/factory.svg';
import statistics from '../../assets/statistics.svg';
import community from '../../assets/community.svg';
import resource from '../../assets/resource.svg';
import empty from '../../assets/empty.svg';

export const SIDEBAR_MENU_ITEMS: SidebarMenuItem[] = [
    {
        titre: 'Accueil',
        url: 'https://1001parcours.cci.fr/intro',
        icon: factory
    },
    {
        titre: 'Mon espace',
        icon: workspace,
        children: [
            {
                titre: 'Mes formations',
                url: 'https://1001parcours.cci.fr/mes-formations',
                icon: empty
            },
            {
                titre: 'Mes évaluations',
                url: 'https://1001parcours.cci.fr/mes-evaluations',
                icon: empty
            },
            {
                titre: 'Mes projets',
                url: 'https://1001parcours.cci.fr/mes-projets',
                icon: empty
            }, {
                titre: 'Mes favoris',
                url: 'https://1001parcours.cci.fr/mes-favoris',
                icon: empty
            },
            {
                titre: 'Mes playlists',
                url: 'https://1001parcours.cci.fr/mes-playlists',
                icon: empty
            },
            {
                titre: 'Mes achats',
                url: 'https://1001parcours.cci.fr/mes-achats',
                icon: empty
            },
        ]
    },
    {
        titre: 'Evaluations',
        icon: statistics,
        children: [
            {
                titre: 'Créer un questionnaire',
                url: 'https://1001parcours.cci.fr/creer-questionnaire',
                appLink: '1001parcours-positionnement',
                icon: empty
            },
            {
                titre: 'Créer une question',
                url: 'https://1001parcours.cci.fr/creer-question',
                appLink: '1001parcours-positionnement',
                icon: empty
            },
            {
                titre: 'Créer un référentiel',
                url: 'https://1001parcours.cci.fr/creer-referentiel',
                appLink: '1001parcours-positionnement',
                icon: empty
            },
            {
                titre: 'Consulter les compétences',
                url: 'https://1001parcours.cci.fr/consulter-competences',
                appLink: '1001parcours-positionnement',
                icon: empty
            },
            {
                titre: 'Consulter les métiers',
                url: 'https://1001parcours.cci.fr/consulter-metiers',
                appLink: '1001parcours-positionnement',
                icon: empty
            },
            {
                titre: 'Passer une évaluation',
                url: 'https://1001parcours.cci.fr/passer-evaluation',
                appLink: '1001parcours-positionnement',
                icon: empty
            },
        ]
    },
    {
        titre: 'Projets',
        icon: courses,
        children: [
            {
                titre: 'Proposer une idée',
                url: 'https://1001parcours.cci.fr/proposer-idee',
                icon: empty
            },
            {
                titre: 'Voter pour une idée',
                url: 'https://1001parcours.cci.fr/voter-projet',
                icon: empty
            },
            {
                titre: 'Rejoindre un projet',
                url: 'https://1001parcours.cci.fr/rejoindre-projet',
                icon: empty
            },
        ]
    },
    {
        titre: 'Ressources',
        icon: resource,
        children: [
            {
                titre: 'Chercher une ressource',
                url: 'https://1001parcours.cci.fr/chercher-ressource',
                icon: empty
            },
            {
                titre: 'Consulter les ressources CCI',
                url: 'https://1001parcours.cci.fr/consulter-ressource-cci',
                icon: empty
            },
            {
                titre: 'Ajouter une ressource',
                url: 'https://1001parcours.cci.fr/ajouter-ressource',
                icon: empty
            },
        ]
    },
    {
        titre: 'Aide',
        icon: books,
        children: [
            {
                titre: 'FAQ',
                url: 'https://1001parcours.cci.fr/faq',
                icon: empty
            },
            {
                titre: 'Faire un retour',
                url: 'https://1001parcours.cci.fr/faire-retour',
                icon: empty
            },
            {
                titre: 'Poser une question',
                url: 'https://1001parcours.cci.fr/poser-question',
                icon: empty
            },
        ]
    },
    {
        titre: 'Annuaire',
        url: 'https://1001parcours.cci.fr/annuaire',
        icon: community
    },
]
