import "./scss/main.scss";
import Appbar from "./components/appbar/appbar";
import Sidebar from "./components/sidebar/sidebar";
import {MeupCommonOptions} from "./model/meup-common-options";


export const init = (options?: MeupCommonOptions): { sidebar: Sidebar, appbar: Appbar } => {
    options = options ?? {};

    const sidebar = new Sidebar();
    const appbar = new Appbar();
    sidebar.setOptions(options, {
        onVisibilityChange: (is_visible) => appbar.refreshMenuButtonState(is_visible)
    });
    appbar.setOptions(options, {
        onMenuButtonClick: () => sidebar.toggle()
    })
    return {sidebar, appbar};
}
const initRef = init;


// eslint-disable-next-line @typescript-eslint/no-namespace
export class MEUPCommon {
    init(options?: MeupCommonOptions): { sidebar?: Sidebar, appbar?: Appbar } {
        return initRef(options);
    }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export class MEUPCommonInjector {
    injectScript(base_url: string): Promise<void> {
        return Promise.resolve();
    }
}


