import {AppbarOptions} from "../../model/appbar-options";
import milleEtUnParcoursLogo from '../../assets/1001parcours_logo.png';
import {MeupCommonOptions} from "../../model/meup-common-options";

class Appbar {
    private appbarHTMLElement?: HTMLElement;
    private menuButtonElement?: HTMLElement;
    private meupCommonOptions: MeupCommonOptions;
    private options: AppbarOptions;

    constructor() {
    }

    setOptions(meupCommonOptions: MeupCommonOptions, appbarOptions: AppbarOptions) {
        this.meupCommonOptions = meupCommonOptions;
        this.options = appbarOptions;
    }

    render = (): void => {
        this.appbarHTMLElement = document.createElement('header');
        this.appbarHTMLElement.setAttribute('id', 'milleetunparcours-header');

        this.appbarHTMLElement.innerHTML = `
        <div class="milleetunparcours-header-container">
            <div class="milleetunparcours-header-logo-button-container">
                <button id="milleetunparcours-menu-button" class="hamburger-menu hamburger--spin" type="button">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
                <div class="milleetunparcours-header-logo-wrapper">
                    <img width="80px" class="milleetunparcours-header-logo-1001-parcours" src="${milleEtUnParcoursLogo}" alt="1001parcours logo">
                </div>
            </div>
            <div class="milleetunparcours-header-content-container">
                <input class="milleetunparcours-header-search-bar" type="text" placeholder="Rechercher">
                <div class="milleetunparcours-header-menu-wrapper">
                    <div class="milleetunparcours-header-menu-item"><a href="https://1001parcours.cci.fr/messagerie" alt="Messages">Messages</a></div>
                    <div class="milleetunparcours-header-menu-item"><a href="https://1001parcours.cci.fr/notifications" alt="Notifications">Notifications</a></div>
                    <div class="milleetunparcours-header-menu-item"><a href="https://1001parcours.cci.fr/mon-compte" alt="Mon compte">Mon compte</a></div>
                </div>
            </div>
        </div>
        `;

        document.body.prepend(this.appbarHTMLElement);

        this.menuButtonElement = document.getElementById('milleetunparcours-menu-button');
        this.bindEvents();
    };

    public refreshMenuButtonState = (active: boolean) => {
        this.menuButtonElement.classList.toggle('is-active', active);
    }

    private bindEvents = (): void => {
        //click bouton
        this.menuButtonElement.addEventListener('click', () => {
            this.options.onMenuButtonClick();
        });
    };
}

export default Appbar;
